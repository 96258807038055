import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home";
import News from "../views/News";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/events/:id",
    name: "cyber-events",
    component: News
  }
];

const router = new VueRouter({
  mode: "history",
  routes,
  // eslint-disable-next-line no-unused-vars
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  }
});

export default router;
