<template>
  <div class="news-content">
    <div class="news-content_head">
      <div class="head-bg">
        <img src="@/assets/img/news/news-bg.jpg" alt="" class="lounge-bg_img" />
      </div>

      <div v-if="post" class="container text-center z-100">
        <p class="head-subtitle">{{ post.subtitle | translate }}</p>
        <h1 class="head-title">{{ post.title | translate }}</h1>
        <div class="head-info">
          <div class="info-item">
            <span v-if="post.start" class="info-item_value">{{
              post.start | translate
            }}</span>
            <span v-if="post.date" class="info-item_value">{{
              post.date | translate
            }}</span>
          </div>
          <div v-if="post.start" class="info-item">
            <span class="info-item_title">Reservation:</span>
            <span class="info-item_value">+381 66 123553</span>
          </div>
        </div>
      </div>
    </div>

    <div v-if="post && post.description" class="news-content_text">
      <div class="container text-center">
        <p class="cyber-title mb-10">{{ "news.daily" | translate }}</p>
        <p class="post-text">
          {{ post.description | translate }}
        </p>
        <!-- <div class="post-artists_list">
          <p class="post-artist">Disco Караоке</p>
          <p class="post-artist">Dj set</p>
          <p class="post-artist">Фотограф</p>
          <p class="post-artist">Welcome drink</p>
        </div> -->
      </div>
    </div>
    <news-gallery
      v-if="post && post.gallery"
      :gallery="post.gallery"
    ></news-gallery>
  </div>
</template>

<script>
import NewsGallery from "./news-gallery";
import news from "./news";

export default {
  name: "NewsContent",

  components: {
    NewsGallery
  },

  data: () => ({
    post: null
  }),

  mounted() {
    this.init();
  },

  methods: {
    init() {
      const newss = news;
      if (this.$route.params.id) {
        this.post = newss.news.find(it => it.id === this.$route.params.id);
      } else this.post = news[0];
    }
  },

  watch: {
    $route() {
      this.init();
    }
  }
};
</script>

<style scoped>
.news-content {
  width: 100%;
  position: relative;
}
.news-content .news-content_head {
  position: relative;
  height: 80vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.news-content .news-content_head .head-bg {
  border-radius: 0 0 50% 50array/0array 0 50% 50%;
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  transform: scaleX(1.5);
  background: linear-gradient(90.83deg, #57238a -4.03%, #ad2f2e 99.33%);
  overflow: hidden;
}
.news-content .news-content_head .lounge-bg_img {
  width: 100%;
  height: 100vh;
  position: relative;
  opacity: 0.1;
  -o-object-fit: scale-down;
  object-fit: cover;
  bottom: 120px;
  transform: scaleX(0.666666);
}
.news-content .news-content_head .head-subtitle {
  font-weight: bold;
  font-size: 36px;
  margin-bottom: 25px;
}
.news-content .news-content_head .head-title {
  font-weight: bold;
  font-size: 50px;
  margin-bottom: 25px;
}
.news-content .news-content_head .head-info {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  font-weight: 500;
  font-size: 24px;
  width: 50%;
  margin: 0 auto;
}
.news-content .news-content_head .head-info .info-item_title {
  color: rgba(255, 255, 255, 0.5);
}
.news-content .news-content_head .head-info .info-item_value {
  font-weight: bold;
}
.news-content .z-100 {
  z-index: 10;
}
.news-content .text-center {
  text-align: center;
}
.news-content .news-content_text {
  padding: 150px 0 55px;
}
.news-content .news-content_text .mb-10 {
  margin-bottom: 10px;
}
.news-content .news-content_text .post-text {
  max-width: 70%;
  margin: 0 auto;
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 20px;
}
.news-content .news-content_text .post-artist {
  font-weight: 500;
  font-size: 18px;
  color: #eddb3b;
  margin-bottom: 5px;
}
@media screen and (max-width: 1024px) {
  .news-content .news-content_head .head-title {
    font-size: 30px;
    margin-bottom: 65px;
  }
  .news-content .news-content_head .head-subtitle {
    font-size: 24px;
    margin-bottom: 15px;
  }
  .news-content .news-content_head .head-info {
    width: 100%;
    flex-direction: column;
  }
  .news-content .news-content_text {
    padding: 55px 0;
  }
  .news-content .news-content_text .post-text {
    max-width: 95%;
  }
}
</style>
