<template>
  <div class="menu-sidebar">
    <div class="sidebar-container">
      <div class="sidebar-header">
        <button
          aria-label="Close sidebar"
          class="button button-menu"
          @click="$emit('close')"
        >
          <i class="icon-close"></i>
        </button>

        <lang-switcher></lang-switcher>
      </div>

      <div class="sidebar-center">
        <a
          href="https://goo.gl/maps/XDeBk4SEbNeJRbAS7"
          target="_blank"
          rel="noopener"
          class="button fragment-button mb-10"
        >
          <span class="button-icon">
            <i class="icon-geo"></i>
          </span>
          <span class="button-content">
            ul. Ive Lole Ribara 18, Kragujevac
          </span>
        </a>
        <a
          href="https://www.instagram.com/cyberpunk.kg"
          target="_blank"
          rel="noopener"
          aria-label="Instagram"
          class="button fragment-button mb-10"
        >
          <span class="button-icon">
            <i class="icon-insta"></i>
          </span>
          <span class="button-content">
            cyberpunk.kg
          </span>
        </a>
        <a
          href="tel:+38166123553"
          class="button fragment-button"
          aria-label="Phone number"
        >
          <span class="button-icon">
            <i class="icon-tel"></i>
          </span>
          <span class="button-content">
            +381 66 123553
          </span>
        </a>
      </div>

      <div class="sidebar-bottom">
        <button
          class="cyber-button filled-button"
          @click="goDown('cyber-footer')"
        >
          {{ "intro.button1" | translate }}
        </button>
        <a href="https://menu.cyberbar.club" target="_blank">
          <button class="cyber-button border-button">
            {{ "intro.button2" | translate }}
          </button></a
        >
      </div>
    </div>
  </div>
</template>

<script>
import LangSwitcher from "../lang-switch/LangSwitch";

export default {
  name: "MenuSidebar",

  components: {
    LangSwitcher
  },

  methods: {
    goDown(to) {
      this.$emit("goto", to);
    }
  }
};
</script>

<style>
.menu-sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  max-width: 768px;
  background: #1e0830;
  z-index: 9999;
}
.menu-sidebar .sidebar-container {
  height: 100%;
  width: 100%;
  padding: 10px 10px 100px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  box-sizing: border-box;
}
.menu-sidebar .sidebar-center .button {
  justify-content: flex-start;
}
.menu-sidebar .sidebar-center .mb-10 {
  margin-bottom: 15px;
}
.menu-sidebar .sidebar-header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.menu-sidebar .sidebar-bottom {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.menu-sidebar .sidebar-bottom a {
  text-decoration: none;
}
.menu-sidebar .sidebar-bottom .cyber-button {
  height: 65px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  font-weight: 900;
  font-size: 24px;
  line-height: 24px;
  font-family: "Blender Pro";
  box-sizing: border-box;
  padding-top: 5px;
  cursor: pointer;
}
.menu-sidebar .sidebar-bottom .cyber-button.filled-button {
  background: url("~@/assets/img/cyber-button.svg");
  width: 325px;
  color: #231a2e;
  background-size: 100%;
  background-repeat: no-repeat;
}
.menu-sidebar .sidebar-bottom .cyber-button.border-button {
  color: #fff;
  background-repeat: no-repeat;
  margin-left: 17px;
  width: 325px;
  background: url("~@/assets/img/cyber-button_b_xs.svg");
  background-size: 100%;
  margin: 15px 0 0 0;
}
</style>
