<template>
  <div>
    <main-preview
      v-if="preview"
      @close="closeMa"
      v-scroll-lock="preview"
    ></main-preview>
    <div class="cyber-color">
      <lounge-intro></lounge-intro>

      <chilim-text></chilim-text>

      <cyber-poster></cyber-poster>

      <hookah-slider></hookah-slider>

      <menu-book></menu-book>

      <cyber-footer></cyber-footer>
    </div>
  </div>
</template>

<script>
import LoungeIntro from "@/components/intro/lounge-intro";
import ChilimText from "@/components/chilim-blocks/chilim-text";
import CyberPoster from "@/components/chilim-blocks/cyber-banner";
import HookahSlider from "@/components/chilim-blocks/hookah-slider";
import MenuBook from "@/components/menu-book/menu-book";
import CyberFooter from "@/components/footer/main-footer";

import MainPreview from "@/components/chilim-blocks/main-preview";
import { mapState, mapActions } from "vuex";

export default {
  name: "Home",

  components: {
    LoungeIntro,
    ChilimText,
    CyberPoster,
    HookahSlider,
    MenuBook,
    CyberFooter,
    MainPreview
  },

  computed: {
    ...mapState(["preview"])
  },

  methods: {
    ...mapActions(["changePreview"]),

    closeMa() {
      this.changePreview();
    }
  }
};
</script>

<style>
.cyber-color {
  background: #1e0830;
}
</style>
