<template>
  <div class="news-digest" id="news">
    <div class="container">
      <div class="cyber-title">{{ "news.title" | translate }}</div>
      <div class="row a-center j-between">
        <swiper
          @ready="setControlledSwiper"
          :options="swiperOption"
          @slideChange="getBullets"
          ref="mySwiper2"
          class="swiper"
        >
          <swiper-slide v-for="post in news" :key="post.id">
            <div
              class="news-post"
              @click="
                $router.push({ name: 'cyber-events', params: { id: post.id } })
              "
            >
              <div class="post-image">
                <img :src="post.img" alt="" />
              </div>
              <div class="post-title">{{ post.title | translate }}</div>
              <div class="post-time">{{ $t(post.start || post.date) }}</div>
            </div>
          </swiper-slide>
          <div class="swiper-pagination" slot="pagination"></div>
        </swiper>
      </div>

      <svg width="0" height="0">
        <defs>
          <clipPath id="clip-path">
            <path
              d="M34.1566 0.5H366.183V174.41V174.91H366.683H376.813L366.472 185.5H13.7617L0.5 174.459V33.5653L34.1566 0.5Z"
            />
          </clipPath>
        </defs>
      </svg>
    </div>
  </div>
</template>

<script>
import news from "./news";

export default {
  name: "NewsDigest",

  computed: {
    sliderPerV() {
      return window.innerWidth >= 768 ? 3 : 1;
    }
  },

  data: () => ({
    swiperOption: {
      slidesPerView: window.innerWidth >= 768 ? 3 : 1,
      spaceBetween: 23,
      pagination: {
        el: ".swiper-pagination",
        clickable: true
      }
    },
    news: [],
    controlledSwiper: null
  }),

  mounted() {
    this.swiperOption.slidesPerView = this.sliderPerV;
    this.news = news.news;
  },

  methods: {
    setControlledSwiper(swiper) {
      this.controlledSwiper = swiper;
    },
    getBullets() {
      const me = document.getElementById("news");
      const me_list = me.getElementsByClassName("swiper-pagination-bullet");
      for (let i = 0; i < me_list.length; i++) {
        me_list[i].classList.remove("history-bullet");
      }
      if (this.controlledSwiper.activeIndex === 0) return true;
      for (let i = 0; i <= this.controlledSwiper.activeIndex; i++) {
        me_list[i].classList.add("history-bullet");
      }
    }
  }
};
</script>

<style scoped>
.news-digest {
  position: relative;
  width: 100%;
}
.news-digest .cyber-title {
  width: 100%;
  text-align: center;
  margin-bottom: 20px;
}
.news-digest .news-post {
  position: relative;
  cursor: pointer;
  transition: 0.2s ease-in-out;
}
.news-digest .news-post .post-image {
  position: relative;
  margin-bottom: 15px;
  width: 378px;
  height: 186px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.news-digest .news-post .post-image img {
  width: 100%;
  height: 100%;
  position: absolute;
  object-fit: cover;
  -webkit-clip-path: url(#clip-path);
  clip-path: url(#clip-path);
}
.news-digest .news-post .post-image::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0px;
  top: 0px;
  background: url("~@/assets/img/news/post-border.svg");
  background-repeat: no-repeat;
  background-size: 100%;
}
.news-digest .news-post .post-title {
  font-weight: 500;
  font-size: 20px;
  color: #fff;
  margin-bottom: 5px;
}
.news-digest .news-post .post-time {
  font-weight: 500;
  font-size: 16px;
  color: #eddb3b;
}
@media screen and (max-width: 768px) {
  .news-digest {
    padding: 0 20px;
  }
  .news-digest .swiper-container {
    padding: 0 0 30px;
  }
}
</style>
