import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VScrollLock from "v-scroll-lock";
import VueAwesomeSwiper from "vue-awesome-swiper";
import vClickOutside from "v-click-outside";

Vue.use(VueAwesomeSwiper);
Vue.use(VScrollLock);
Vue.use(vClickOutside);

import "./assets/style/main.css";
import "swiper/css/swiper.min.css";

import "./i18n";

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
