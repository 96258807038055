<template>
  <div class="main-header">
    <div class="header-container">
      <div class="row j-between a-center">
        <div class="menu-col d-flex">
          <a
            href="https://goo.gl/maps/XDeBk4SEbNeJRbAS7"
            target="_blank"
            rel="noopener"
            class="button fragment-button j-between d-xs-none"
            aria-label="Cyber address"
          >
            <span class="button-icon">
              <i class="icon-geo"></i>
            </span>
            <span class="button-content">
              ul. Ive Lole Ribara 18, Kragujevac
            </span>
          </a>
          <button
            aria-label="Open sidebar"
            class="button button-menu d-xs"
            @click="sidebar = true"
          >
            <div class="icon-menu">
              <span class="menu-bar"></span>
              <span class="menu-bar"></span>
              <span class="menu-bar"></span>
            </div>
          </button>
        </div>
        <div class="logo-col">
          <div class="logo-col_shadow"></div>
          <img
            src="@/assets/img/cyber_logo.png"
            alt=""
            height="80"
            @click="$router.push('/')"
          />
        </div>
        <div class="menu-col d-flex j-end">
          <a
            v-if="isDesktop"
            class="social-link"
            href="https://www.instagram.com/cyberpunk.kg"
            target="_blank"
            rel="noopener"
            aria-label="Instagram"
          >
            <i class="icon-insta"></i>
          </a>
          <a
            href="tel:+38166123553"
            class="button fragment-button j-between minify-button"
            aria-label="Phone number"
          >
            <span class="button-icon">
              <i class="icon-tel"></i>
            </span>
            <span class="button-content d-xs-none">
              +381 66 123553
            </span>
          </a>
          <lang-switch class="ml-20 d-xs-none"></lang-switch>
        </div>
      </div>
    </div>

    <menu-sidebar
      v-show="sidebar"
      @close="sidebar = false"
      @goto="goDown"
      v-scroll-lock="sidebar"
    ></menu-sidebar>
  </div>
</template>

<script>
import LangSwitch from "../lang-switch/LangSwitch";
import MenuSidebar from "./menu-sidebar";

export default {
  name: "MainHeader",

  components: {
    MenuSidebar,
    LangSwitch
  },

  data: () => ({
    sidebar: false
  }),

  computed: {
    overBody() {
      return this.sidebar
        ? (document.body.style.position = "fixed")
        : (document.body.style.position = "");
    },
    isDesktop() {
      return window.innerWidth > 1024;
    }
  },

  methods: {
    goDown(to) {
      this.sidebar = false;
      var elmnt = document.getElementById(to);
      setTimeout(() => {
        elmnt.scrollIntoView();
      }, 100);
    }
  }
};
</script>

<style scoped>
.main-header {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  z-index: 100;
}
.main-header .header-container {
  padding: 10px 30px 0;
}
.main-header .header-container .menu-col {
  flex-basis: 30%;
}
.main-header .header-container .menu-col .button-menu {
  display: none;
}
.main-header .header-container .menu-col .social-link {
  z-index: 1;
  width: 50px;
  height: 50px;
  margin-right: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(255, 255, 255, 0.3);
  box-sizing: border-box;
}
.main-header .header-container .logo-col {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.main-header .header-container .logo-col .logo-col_shadow {
  background: radial-gradient(
    50% 50% at 50% 50%,
    #000 0%,
    rgba(0, 0, 0, 0) 100%
  );
  opacity: 0.3;
  position: absolute;
  width: 716px;
  height: 320px;
}
.main-header .header-container .logo-col img {
  position: relative;
}
@media screen and (max-width: 1024px) {
  .main-header .header-container {
    padding: 10px 10px 0;
  }
  .main-header .header-container .menu-col {
    flex-basis: 51px;
  }
  .main-header .header-container .menu-col .button-menu {
    display: flex;
    justify-content: center;
    padding: 0;
    width: 100%;
  }
  .main-header .header-container .menu-col .button-menu .icon-menu {
    width: 24px;
    height: 17px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
  }
  .main-header .header-container .menu-col .button-menu .icon-menu .menu-bar {
    height: 3px;
    width: 100%;
    background: #fff;
  }
  .main-header .header-container .logo-col .logo-col_shadow {
    width: 100%;
    height: 161px;
  }
  .main-header .header-container .logo-col img {
    height: 50px;
  }
}
</style>
