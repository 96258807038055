<template>
  <div class="cyber-footer" id="cyber-footer">
    <div class="container text-center">
      <p class="footer-title">You are welcomed</p>

      <div class="footer-reserve">
        <p class="footer-reserve_title">{{ "footer.reserv" | translate }}</p>
        <div class="reserve-number">
          <span class="reserve-number_label">{{
            "footer.bynumb" | translate
          }}</span>
          <a
            aria-label="Mobile phone"
            href="tel:+38166123553"
            class="reserve-number_phone"
          >
            +381 66 123553
          </a>
        </div>
      </div>

      <a
        class="haha-classic mb-38"
        href="https://goo.gl/maps/XDeBk4SEbNeJRbAS7"
        target="_blank"
        rel="noopener"
        aria-label="Cyber address"
      >
        <i class="icon-geoy"></i>
        <p class="footer-address">ul. Ive Lole Ribara 18, Kragujevac</p>
      </a>

      <div class="haha-classic mb-100">
        <i class="icon-time"></i>
        <p class="footer-address mb5">{{ "footer.woktime" | translate }}</p>

        <div class="work-time mb5">
          {{ "footer.time1" | translate }}:
          <span class="yellow">12:00 - 00:00</span>
        </div>
        <div class="work-time mb5">
          {{ "footer.time2" | translate }}:
          <span class="yellow">12:00 - 1:00</span>
        </div>
        <div class="work-time mb5">
          {{ "footer.time3" | translate }}:
          <span class="yellow">12:00 - 00:00</span>
        </div>
      </div>

      <p class="footer-description">
        {{ "footer.desc" | translate }}
      </p>

      <p class="footer-footer">
        {{ "footer.comebebe" | translate }}
      </p>

      <img
        src="@/assets/img/footer/footer-smok1.png"
        alt=""
        class="cyber-footer_smoke"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer"
};
</script>

<style scoped>
.cyber-footer {
  height: 770px;
  width: 100%;
  position: relative;
  box-sizing: border-box;
  padding: 90px 0 50px;
  background: url("~@/assets/img/footer/footer-back.png");
  background-repeat: no-repeat;
  background-position: center bottom;
  mix-blend-mode: lighten;
}
.cyber-footer::before {
  content: "";
  position: absolute;
  top: -12px;
  left: 0;
  width: 100%;
  height: 30px;
  background: url("~@/assets/img/footer/footer-border.svg");
  background-position: top center;
  background-size: cover;
}
.cyber-footer .container {
  overflow: hidden;
  padding-bottom: 45px;
}
.cyber-footer .cyber-footer_smoke {
  position: absolute;
  left: 35%;
  top: 245px;
  opacity: 0.4;
  transform: rotate(-87.52deg);
}
.cyber-footer .text-center {
  text-align: center;
}
.cyber-footer .footer-title {
  font-weight: normal;
  font-size: 70px;
  font-family: "Cyberpunk";
  color: #fff;
  text-align: center;
  margin-bottom: 44px;
}
.cyber-footer .footer-reserve_title {
  font-weight: bold;
  font-size: 30px;
  margin-bottom: 9px;
}
.cyber-footer .footer-reserve {
  margin-bottom: 30px;
}
.cyber-footer .reserve-number {
  position: relative;
  width: 302px;
  height: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  background: url("~@/assets/img/footer/reserve-border.svg");
  background-repeat: no-repeat;
}
.cyber-footer .reserve-number .reserve-number_label {
  color: #eddb3b;
  font-weight: bold;
  font-size: 14px;
  position: absolute;
  left: 13px;
  top: -8px;
}
.cyber-footer .reserve-number .reserve-number_phone {
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.1em;
  color: #fff;
  text-decoration: none;
}
.cyber-footer .haha-classic {
  text-decoration: none;
  color: #fff;
  display: block;
}
.cyber-footer .haha-classic i {
  margin-bottom: 6px;
}
.cyber-footer .footer-address {
  font-weight: bold;
  font-size: 18px;
}
.cyber-footer .mb-38 {
  margin-bottom: 38px;
}
.cyber-footer .mb-100 {
  margin-bottom: 100px;
}
.cyber-footer .mb5 {
  margin-bottom: 5px;
}
.cyber-footer .work-time {
  color: #7c7c7c;
  font-weight: bold;
  font-size: 18px;
}
.cyber-footer .work-time .yellow {
  color: #eddb3b;
}
.cyber-footer .footer-description {
  font-weight: bold;
  font-size: 18px;
  color: #fff;
  margin-bottom: 19px;
}
.cyber-footer .footer-footer {
  font-weight: bold;
  font-size: 18px;
}
@media screen and (max-width: 1024px) {
  .cyber-footer {
    height: auto;
    padding: 90px 20px 50px;
  }
  .cyber-footer .footer-title {
    font-size: 50px;
  }
}
</style>
