const g1_1 = require("@/assets/img/news/g1/image1.jpg");
const g1_2 = require("@/assets/img/news/g1/image2.jpg");
const g1_3 = require("@/assets/img/news/g1/image3.jpg");
const g1_4 = require("@/assets/img/news/g1/image4.jpg");
const g1_5 = require("@/assets/img/news/g1/image5.jpg");
const g1_6 = require("@/assets/img/news/g1/image6.jpg");
const g1_7 = require("@/assets/img/news/g1/image7.jpg");
const g1_8 = require("@/assets/img/news/g1/image8.jpg");
const g1_9 = require("@/assets/img/news/g1/image9.jpg");
const g1_10 = require("@/assets/img/news/g1/image10.jpg");
const g1_11 = require("@/assets/img/news/g1/image11.jpg");
const g1_12 = require("@/assets/img/news/g1/image12.jpg");
const g1_13 = require("@/assets/img/news/g1/image13.jpg");
const g1_14 = require("@/assets/img/news/g1/image14.jpg");
const g1_15 = require("@/assets/img/news/g1/image15.jpg");
const g1_16 = require("@/assets/img/news/g1/image16.jpg");

const post1 = require("@/assets/img/news/news2.png");
const post2 = require("@/assets/img/news/post2.jpg");
const post3 = require("@/assets/img/news/post3.jpg");

const gallery1 = [
  g1_7,
  g1_1,
  g1_2,
  g1_3,
  g1_4,
  g1_5,
  g1_6,
  g1_8,
  g1_9,
  g1_10,
  g1_11,
  g1_12,
  g1_13,
  g1_14,
  g1_15,
  g1_16
];

export default {
  news: [
    {
      id: "cyber-katleta",
      mintitle: "news.post1.mintitle",
      title: "news.post1.title",
      subtitle: "news.post1.subtitle",
      description: "news.post1.desc",
      start: "news.post1.start",
      img: post1
    },
    {
      id: "cyber-mocco",
      mintitle: "news.post2.mintitle",
      title: "news.post2.title",
      subtitle: "news.post2.subtitle",
      description: "news.post2.desc",
      start: "news.post2.start",
      img: post2
    },
    {
      id: "cyber-hookah",
      mintitle: "news.post3.mintitle",
      title: "news.post3.title",
      subtitle: "news.post3.subtitle",
      date: "news.post3.date",
      gallery: gallery1,
      img: post3
    }
  ]
};
