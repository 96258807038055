<template>
  <div class="cyber-poster">
    <div id="hero" class="cyber-poster_bg"></div>
  </div>
</template>

<script>
import { gsap } from "gsap";

import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export default {
  name: "CyberBanner",

  mounted() {
    const h = window.innerHeight > 800 ? -0.15 : -0.12;
    // if (window.innerWidth > 1024) {
    //   document.getElementById("hero").style.backgroundPosition = `50% ${-h /
    //     2}px`;

    //   gsap.to("#hero", {
    //     backgroundPosition: `50% ${200 / 2}px`,
    //     ease: "none",
    //     scrollTrigger: {
    //       trigger: ".cyber-poster",
    //       scrub: true
    //     }
    //   });
    // }
    if (window.innerWidth > 1024) {
      const p1 = document.getElementById("hero");

      window.addEventListener("scroll", function() {
        let offset = window.pageYOffset;
        p1.style.backgroundPositionY = offset * h + "px";
      });
    }
  }
};
</script>

<style scoped>
.cyber-poster {
  margin-top: 50px;
  width: 100%;
  height: 500px;
  position: relative;
  overflow: hidden;
}

.cyber-poster::before {
  content: "";
  position: absolute;
  top: -40px;
  left: 0;
  right: 0;
  height: 55px;
  width: 100%;
  background: url("~@/assets/img/poster-border_top.svg");
  z-index: 2;
}

.cyber-poster_bg {
  width: 100%;
  height: 100%;
  position: absolute;
  background: url("~@/assets/img/cyber-poster.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  z-index: 1;
}

.cyber-poster::after {
  content: "";
  position: absolute;
  bottom: -40px;
  left: 0;
  right: 0;
  height: 55px;
  width: 100%;
  background: url("~@/assets/img/poster-border_bot.svg");
  z-index: 2;
}

@media screen and (max-width: 1024px) {
  .cyber-poster {
    height: 400px;
  }
}
</style>
