<template>
  <div class="lang-switcher" :class="{ open: show }">
    <div class="button" @click="openDrop">
      <span class="button-content">{{ selectedLang.title | translate }}</span>
    </div>
    <div class="lang-switcher__options" v-if="show" v-click-outside="closeDrop">
      <div
        class="lang-switcher__options-item"
        v-for="item in langs"
        :key="item.id"
        @click="changeLang(item)"
      >
        <p class="button-content">{{ item.title | translate }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";

export default {
  name: "LangSwitcher",

  mounted() {
    let lang = localStorage.getItem("lang");
    if (lang) {
      this.selectedLang = this.langs.find(it => it.key === lang);
      Vue.i18n.set(this.selectedLang.key);
    }
  },

  data: () => ({
    langs: [
      { id: 0, title: "base.sr", key: "sr" },
      { id: 1, title: "base.ru", key: "ru" },
      { id: 2, title: "base.en", key: "en" }
    ],
    selectedLang: { id: 0, title: "base.sr", key: "sr" },
    show: false
  }),

  methods: {
    changeLang(item) {
      this.selectedLang = item;
      Vue.i18n.set(this.selectedLang.key);
      this.closeDrop();
    },

    openDrop() {
      this.show = true;
    },

    closeDrop() {
      this.show = false;
    }
  },

  watch: {
    selectedLang(val) {
      localStorage.setItem("lang", val.key);
    }
  }
};
</script>

<style scoped>
.lang-switcher {
  position: relative;
  cursor: pointer;
}
.lang-switcher .button {
  user-select: none;
}
.lang-switcher .lang-switcher__options {
  position: absolute;
  top: 55px;
  left: 0;
  right: 0;
  width: 100%;
  background: none;
  border: 1px solid rgba(255, 255, 255, 0.3);
  z-index: 9999;
  padding: 5px 0;
}
.lang-switcher .lang-switcher__options .lang-switcher__options-item {
  width: 100%;
  padding: 3px 0 0;
  text-align: center;
}
</style>
