<template>
  <div class="news-gallery">
    <swiper :options="swiperOption">
      <swiper-slide v-for="slide in gallery" :key="slide.id">
        <img :src="slide" alt="" />
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
export default {
  name: "NewsGallery",

  props: {
    gallery: Array
  },

  data: () => ({
    swiperOption: {
      slidesPerView: "auto",
      spaceBetween: 150,
      effect: "coverflow",
      centeredSlides: "true",
      autoplay: { delay: 5000, disableOnInteraction: false },
      coverflowEffect: {
        rotate: 0,
        stretch: 0,
        depth: 300,
        modifier: 1,
        slideShadows: true
      }
    }
  })
};
</script>

<style scoped>
.news-gallery {
  padding: 150px 0 55px;
}
.news-gallery .swiper-slide {
  width: 570px;
  height: 400px;
  position: relative;
}
.news-gallery .swiper-slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
@media screen and (max-width: 768px) {
  .news-gallery {
    padding: 55px 0;
  }
  .news-gallery .swiper-slide {
    width: 95%;
    height: 270px;
  }
}
</style>
