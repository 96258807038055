<template>
  <div class="lounge-intro">
    <main-header></main-header>

    <div class="lounge-bg_container">
      <div class="lounge-content">
        <p class="lounge-content_subtitle">{{ "intro.title" | translate }}</p>
        <h1 class="lounge-content_title"></h1>
        <div class="lounge-controls">
          <button
            :id="$t('intro.button1')"
            class="cyber-button filled-button glitch-hover"
            @click="goDown('cyber-footer')"
          >
            {{ "intro.button1" | translate }}
          </button>
          <a href="https://menu.cyberbar.club" target="_blank">
            <button
              :id="$t('intro.button2')"
              class="cyber-button border-button glitch-hover"
            >
              {{ "intro.button2" | translate }}
            </button>
          </a>
        </div>
      </div>
      <video autoplay muted playsinline loop class="lounge-bg_video">
        <source src="@/assets/video/site_bg.webm" type="video/webm" />
        <source src="@/assets/video/site_bg.mp4" type="video/mp4" />
      </video>

      <img :src="introBg" alt="" class="ln-t_img" />
    </div>

    <div class="hookah-variety">
      <div class="hookah-border">
        <div class="hookah-cirle"></div>
      </div>
      <div class="container">
        <div class="row j-center">
          <div class="circle-diagram">
            <div class="item-hold">
              <img src="@/assets/img/tabo-dapple.png" alt="" />
            </div>
            <div class="item-hold">
              <img src="@/assets/img/coffee.png" alt="" class="b-0 cd-cf" />
            </div>
            <div class="item-hold">
              <img src="@/assets/img/cocktail.png" alt="" class="cd-ck" />
            </div>
            <div class="item-hold d-xs-none">
              <img src="@/assets/img/tabo-dragon.png" alt="" />
            </div>
          </div>
        </div>
      </div>
      <div class="secret-cotrols">
        <div class="center-arrow">
          <i class="icon-arrows" @click="goDown('chilim')"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MainHeader from "../header/main-header";
import { mapActions } from "vuex";

const en = require("../../assets/img/intro-title-en.gif");
const ru = require("../../assets/img/intro-title.gif");
const sr = require("../../assets/img/intro-title-sr.gif");

export default {
  name: "LoungeIntro",

  components: {
    MainHeader
  },

  data: () => ({
    audio: null,
    intro: {
      en: en,
      ru: ru,
      sr: sr
    }
  }),

  computed: {
    introBg() {
      return this.intro[this.$i18n.locale()];
    }
  },

  methods: {
    ...mapActions(["changeState"]),

    toogleMusic() {
      this.changeState();
    },

    goDown(to) {
      var elmnt = document.getElementById(to);
      elmnt.scrollIntoView();
    }
  }
};
</script>

<style>
.lounge-intro {
  position: relative;
  width: 100vw;
  background: #23192f;
  overflow: hidden;
}
.lounge-bg_container {
  width: 100%;
  height: 90vh;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
.lounge-bg_container .lounge-bg_video {
  position: absolute;
  width: 100%;
  min-width: 1750px;
}
.lounge-bg_container .ln-t_img {
  position: absolute;
  mix-blend-mode: screen;
  width: 80%;
  padding-bottom: 65px;
}
.lounge-bg_container .lounge-bg {
  border-radius: 0 0 50% 50array/0array 0 50% 50%;
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  transform: scaleX(1.5);
  background: linear-gradient(90.83deg, #57238a -4.03%, #ad2f2e 99.33%);
  overflow: hidden;
}
.lounge-bg_container .lounge-bg .lounge-untransform {
  transform: scaleX(0.666666);
}
.lounge-bg_container .lounge-bg .lounge-bg_img {
  width: 100%;
  height: 100vh;
  position: relative;
  opacity: 0.1;
  -o-object-fit: scale-down;
  object-fit: cover;
  bottom: 120px;
}
.lounge-content {
  position: relative;
  padding-top: 15px;
  z-index: 10;
  text-align: center;
}
.lounge-content .lounge-content_title {
  font-size: 88px;
  margin-bottom: 23px;
  height: 106px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.lounge-content .lounge-content_subtitle {
  font-weight: bold;
  font-size: 36px;
  text-align: center;
  margin-bottom: 20px;
}
.lounge-content .lounge-controls {
  display: flex;
  justify-content: center;
  align-items: center;
}
.lounge-content a {
  text-decoration: none;
}
.lounge-content .cyber-button {
  height: 65px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  font-weight: 900;
  font-size: 24px;
  line-height: 24px;
  font-family: "Blender Pro";
  box-sizing: border-box;
  padding-top: 5px;
  cursor: pointer;
}
.lounge-content .cyber-button.filled-button {
  background: url("~@/assets/img/cyber-button.svg");
  width: 325px;
  color: #231a2e;
  background-size: 100%;
  background-repeat: no-repeat;
}
.lounge-content .cyber-button.border-button {
  background: url("~@/assets/img/cyber-button_b.svg");
  width: 235px;
  color: #fff;
  background-size: 100%;
  background-repeat: no-repeat;
  margin-left: 17px;
}
.secret-cotrols {
  position: absolute;
  top: 0;
  left: 30px;
  right: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}
.secret-cotrols .music-control {
  height: 50px;
  padding: 0 15px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: bold;
  font-size: 24px;
  cursor: pointer;
}
.secret-cotrols .music-control .music-control_title {
  color: #787878;
}
.secret-cotrols .music-control .sound-on {
  text-decoration: underline;
  padding-left: 5px;
}
.secret-cotrols .music-control .sound-bar {
  width: 40px;
  height: 20px;
  margin-left: 17px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.secret-cotrols .music-control .sound-bar .bar {
  width: 2px;
  min-height: 1px;
  background: #fff;
}
@keyframes equalize {
  0% {
    height: 2px;
  }
  4% {
    height: 1px;
  }
  8% {
    height: 4px;
  }
  12% {
    height: 5px;
  }
  16% {
    height: 6px;
  }
  20% {
    height: 10px;
  }
  24% {
    height: 1px;
  }
  28% {
    height: 4px;
  }
  32% {
    height: 1px;
  }
  36% {
    height: 9px;
  }
  40% {
    height: 4px;
  }
  44% {
    height: 14px;
  }
  48% {
    height: 11px;
  }
  52% {
    height: 10px;
  }
  56% {
    height: 3px;
  }
  60% {
    height: 8px;
  }
  64% {
    height: 12px;
  }
  68% {
    height: 4px;
  }
  72% {
    height: 4px;
  }
  76% {
    height: 7px;
  }
  80% {
    height: 5px;
  }
  84% {
    height: 10px;
  }
  88% {
    height: 12px;
  }
  92% {
    height: 11px;
  }
  96% {
    height: 8px;
  }
  100% {
    height: 5px;
  }
}
.secret-cotrols .social-link {
  width: 50px;
  height: 50px;
  margin-left: 130px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(255, 255, 255, 0.3);
  box-sizing: border-box;
}
.hookah-variety {
  width: 100%;
  height: 614px;
  position: relative;
}
.hookah-variety .hookah-border {
  border-radius: 50% 50% 0 0 / 50% 50% 0 0;
  position: absolute;
  height: calc(100% + 10px);
  width: 100%;
  left: 0;
  right: 0;
  top: -10px;
  transform: scaleX(1.5);
  background: #1e0830;
  z-index: 1;
  overflow: hidden;
  display: flex;
  justify-content: center;
}
.hookah-variety .hookah-portarait {
  position: absolute;
  top: -630px;
  left: 47%;
  transform: translateX(-50%);
}
.hookah-variety .hookah-tabo {
  position: absolute;
  top: -302px;
}
.hookah-variety .hookah-cirle {
  border: 1px solid rgba(255, 255, 255, 0.2);
  box-sizing: border-box;
  width: 34vw;
  height: 34vw;
  border-radius: 50%;
  transform: scaleX(0.666666) translateX(0px);
  top: -68%;
  position: absolute;
}
.hookah-variety .circle-diagram {
  height: 450px;
  width: 86%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-around;
  box-sizing: border-box;
  padding-right: 10px;
  z-index: 1;
}
.hookah-variety .circle-diagram .item-hold {
  flex-basis: 180px;
  height: 180px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.hookah-variety .circle-diagram .item-hold img {
  filter: drop-shadow(0px 0px 48px rgba(0, 0, 0, 0.55));
  border-radius: 50%;
  position: absolute;
  height: 100%;
}
.hookah-variety .circle-diagram .item-hold img.b-0 {
  border-radius: 0;
}
.hookah-variety .circle-diagram .cd-cf {
  top: 175px;
  left: -20px;
}
.hookah-variety .circle-diagram .cd-ck {
  top: 175px;
  right: -20px;
}
@media screen and (max-width: 1800px) {
  .hookah-variety .hookah-cirle {
    width: 598px;
    height: 598px;
    top: -65%;
  }
  .hookah-variety .circle-diagram {
    height: 300px;
  }
}
@media screen and (max-width: 1500px) {
  .hookah-variety .hookah-border {
    top: -25px;
    height: calc(100% + 25px);
  }
  .hookah-variety .hookah-cirle {
    top: -63%;
  }
}
@media screen and (max-width: 1024px) {
  .lounge-bg_container {
    height: 700px;
    overflow: visible;
  }
  .lounge-bg_container .ln-t_img {
    position: absolute;
    mix-blend-mode: screen;
    width: 100%;
    padding-bottom: 65px;
  }
  .lounge-bg_container .lounge-bg_video {
    min-width: 1500px;
    top: 0;
  }
  .lounge-content {
    padding-top: 140px;
  }
  .lounge-content .lounge-content_subtitle {
    font-size: 28px;
    line-height: 34px;
  }
  .lounge-content .lounge-controls {
    display: flex;
    flex-direction: column;
    margin-top: 65px;
  }
  .lounge-content .cyber-button.border-button {
    width: 325px;
    background: url("~@/assets/img/cyber-button_b_xs.svg");
    background-size: 100%;
    margin: 15px 0 0 0;
  }
  .hookah-variety {
    height: 365px;
  }
  .hookah-variety .hookah-border {
    top: 0;
    left: 50%;
    width: 1312px;
    transform: translateX(-50%);
  }
  .hookah-variety .hookah-cirle {
    transform: none;
    width: 489px;
    height: 489px;
    top: -85%;
  }
  .hookah-variety .circle-diagram {
    width: 100%;
    height: 400px;
    padding: 0;
  }
  .hookah-variety .circle-diagram .d-xs-none {
    display: none;
  }
  .hookah-variety .circle-diagram .item-hold {
    flex-basis: 112px;
    height: 112px;
  }
  .hookah-variety .circle-diagram .cd-cf {
    top: 45px;
    left: 0;
  }
  .hookah-variety .circle-diagram .cd-ck {
    top: 0;
    right: 0;
  }
  .secret-cotrols {
    left: 10px;
    right: 10px;
    top: -70px;
  }
  .secret-cotrols .music-control {
    font-weight: bold;
    font-size: 18px;
  }
  .secret-cotrols .music-control canvas {
    display: none;
  }
  .secret-cotrols .music-control .sound-bar {
    display: none;
  }
  .secret-cotrols .social-link {
    margin-left: 57px;
  }
  .secret-cotrols .center-arrow {
    position: relative;
    top: 70px;
  }
}
@media screen and (max-width: 768px) {
  .lounge-bg_container {
    height: 90vh;
  }
  .lounge-bg_container .lounge-bg_video {
    top: 50%;
    width: 1938px;
    transform: translate(-50%, -50%);
    left: 50%;
    z-index: 0;
    position: absolute;
  }
}
@media screen and (max-height: 850px) {
  .hookah-variety .hookah-cirle {
    top: -56%;
  }
}
@media screen and (max-height: 750px) {
  .hookah-variety .hookah-cirle {
    top: -53%;
  }
}
</style>
