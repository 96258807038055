<template>
  <div class="chilim-text" id="chilim">
    <div class="container">
      <div class="row j-between wrap">
        <div class="title-col">
          <div class="cyber-title">{{ "about.title" | translate }}</div>
        </div>

        <div class="content-col left-content">
          <div class="cyber-border_left">
            <div class="content-col_text">
              {{ "about.text1" | translate }}
            </div>
          </div>
        </div>

        <div class="chilim-col">
          <div class="chilim-container">
            <img
              src="@/assets/img/chilim-smoke.png"
              alt=""
              class="chilim-smoke"
            />
            <img
              src="@/assets/img/chilim-coal.png"
              alt=""
              class="chilim-coal"
            />
          </div>
        </div>

        <div class="content-col right-content">
          <div class="cyber-border_right">
            <div class="content-col_text">{{ "about.text2" | translate }}</div>
          </div>
        </div>
      </div>

      <img
        src="@/assets/img/chilim-spark.png"
        alt=""
        class="chil-spark right-spark"
      />
      <img
        src="@/assets/img/chilim-spark.png"
        alt=""
        class="chil-spark left-spark"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "ChilimText"
};
</script>

<style scoped>
.chilim-text {
  width: 100%;
  position: relative;
  background: #1e0830;
}
.chilim-text .chil-spark {
  mix-blend-mode: lighten;
  position: absolute;
}
.chilim-text .chil-spark.left-spark {
  bottom: 11%;
  left: -8%;
  opacity: 0.5;
}
.chilim-text .chil-spark.right-spark {
  top: 3%;
  right: 0%;
}
.chilim-text .title-col {
  position: absolute;
  left: 51%;
  top: 50px;
  transform: translateX(-50%);
  z-index: 100;
}
.chilim-text .content-col {
  flex-basis: 35%;
  height: 100%;
}
.chilim-text .content-col.left-content {
  padding-top: 220px;
}
.chilim-text .content-col.right-content {
  align-self: center;
}
.chilim-text .content-col .content-col_text {
  font-weight: 500;
  font-size: 18px;
  color: #fff;
}
.chilim-text .content-col .cyber-border_left {
  position: relative;
}
.chilim-text .content-col .cyber-border_left::before {
  content: "";
  width: 353.5px;
  height: 115px;
  background: url("~@/assets/img/cyber-border_left.svg");
  position: absolute;
  top: -45px;
  right: 70px;
}
.chilim-text .content-col .cyber-border_right {
  position: relative;
}
.chilim-text .content-col .cyber-border_right::before {
  content: "";
  width: 389px;
  height: 178px;
  background: url("~@/assets/img/cyber-border_right.svg");
  position: absolute;
  top: -58px;
  left: 55px;
}
.chilim-text .chilim-col {
  flex-basis: 20%;
}
.chilim-text .chilim-col .chilim-container {
  min-height: 900px;
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
}
.chilim-text .chilim-col .chilim-smoke {
  position: absolute;
  top: -20px;
  z-index: 10;
}
.chilim-text .chilim-col .chilim-coal {
  position: absolute;
  bottom: 0;
  mix-blend-mode: lighten;
  z-index: 1;
}
@media screen and (max-width: 1024px) {
  .chilim-text {
    overflow-x: hidden;
  }
  .chilim-text .title-col {
    position: relative;
    top: 0;
    left: 0;
    transform: none;
    text-align: center;
    width: 100%;
  }
  .chilim-text .content-col {
    flex-basis: 100%;
    height: auto;
    position: relative;
    z-index: 100;
  }
  .chilim-text .content-col.left-content {
    padding-top: 100px;
    padding-left: 20px;
    order: 1;
  }
  .chilim-text .content-col.right-content {
    align-self: center;
    order: 2;
    padding-top: 130px;
    padding-left: 20px;
    box-sizing: border-box;
  }
  .chilim-text .chilim-col {
    flex-basis: 100%;
    order: 3;
  }
  .chilim-text .chilim-col .chilim-container {
    min-height: 400px;
  }
  .chilim-text .chilim-col .chilim-smoke {
    top: auto;
    bottom: 178px;
  }
}
</style>
