import Vue from "vue";

// add translations directly to the application
Vue.i18n.add("en", require("./en.json"));
Vue.i18n.add("sr", require("./sr.json"));
// Vue.i18n.add('zhHans', require('./ch.json'))
Vue.i18n.add("ru", require("./ru.json"));
// set the start locale to use
Vue.i18n.set("sr");

// set fallback for non-translated strings
Vue.i18n.fallback("sr");
