import Vue from "vue";
import Vuex from "vuex";
import vuexI18n from "vuex-i18n";

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    preview: true
  },
  mutations: {
    ["TOOGLE_PREV"](state) {
      state.preview = false;
    }
  },
  actions: {
    changePreview({ commit }) {
      commit("TOOGLE_PREV");
    }
  },
  modules: {}
});

Vue.use(vuexI18n.plugin, store);

export default store;
