<template>
  <div class="cyber-news">
    <main-header></main-header>

    <news-content></news-content>

    <div class="cyber-news_others">
      <news-digest></news-digest>
    </div>

    <main-footer></main-footer>
  </div>
</template>

<script>
import MainHeader from "@/components/header/main-header";
import NewsDigest from "@/components/news-list/news-digest";
import MainFooter from "@/components/footer/main-footer";
import NewsContent from "@/components/news-list/news-content";

export default {
  name: "NewsPage",

  components: {
    MainHeader,
    MainFooter,
    NewsDigest,
    NewsContent
  }
};
</script>

<style scoped>
.cyber-news_others {
  height: 637px;
  width: 100%;
  display: flex;
  align-items: flex-end;
  padding-bottom: 80px;
  background: url("~@/assets/img/menu-bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
}
.cyber-news_others::before {
  content: "";
  width: 100%;
  height: 186px;
  position: absolute;
  top: -1px;
  background: url("~@/assets/img/hookah-border.svg");
  background-size: 100%;
  background-repeat: no-repeat;
}
</style>
