<template>
  <transition name="fade">
    <div @touchmove.prevent="preventScroll" class="main-preview parallax">
      <div
        class="main-preview_container parallax-layer"
        data-parallax-deep="1000"
      >
        <div class="preview-logo">
          <img
            src="@/assets/img/cyber_logo.png"
            alt=""
            class="preview-logo_img"
          />
        </div>

        <div v-if="preview" class="preview-title">
          {{ "intro.title" | translate }}
        </div>

        <button class="preview-button" @click="$emit('close')">
          {{ "intro.enter" | translate }}
        </button>
      </div>

      <img
        v-for="(n, i) in smoke"
        :key="i"
        :src="n"
        alt=""
        class="pr-sm parallax-layer"
        :class="`smoke${i + 1}`"
        data-parallax-deep="100"
      />
    </div>
  </transition>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { gsap } from "gsap";

const img1 = require("@/assets/img/preview/smoke1.png");
const img2 = require("@/assets/img/preview/smoke2.png");
const img3 = require("@/assets/img/preview/smoke3.png");
const img4 = require("@/assets/img/preview/smoke4.png");
const img5 = require("@/assets/img/preview/smoke5.png");
const img6 = require("@/assets/img/preview/smoke6.png");
const img7 = require("@/assets/img/preview/smoke7.png");
const img8 = require("@/assets/img/preview/smoke8.png");
const img9 = require("@/assets/img/preview/smoke9.png");
const img10 = require("@/assets/img/preview/smoke10.png");
const img11 = require("@/assets/img/preview/smoke11.png");
const img12 = require("@/assets/img/preview/smoke12.png");

export default {
  name: "Main-Preview",

  data: () => ({
    smoke: [
      img1,
      img2,
      img3,
      img4,
      img5,
      img6,
      img7,
      img8,
      img9,
      img10,
      img11,
      img12
    ]
  }),

  computed: {
    ...mapState(["preview"])
  },

  mounted() {
    let tl = gsap.timeline({ delay: 1 });
    tl.from(".main-preview_container", { y: 20, opacity: 0 });
    tl.from(".pr-sm", { opacity: 0, scale: 0.7, duration: 2.5 });

    window.addEventListener("load", function() {
      this.setTimeout(() => {
        new Parallax();
      }, 3800);
    });

    function Parallax(options) {
      options = options || {};
      this.nameSpaces = {
        wrapper: options.wrapper || ".parallax",
        layers: options.layers || ".parallax-layer",
        deep: options.deep || "data-parallax-deep"
      };
      this.init = function() {
        var self = this,
          parallaxWrappers = document.querySelectorAll(this.nameSpaces.wrapper);
        for (var i = 0; i < parallaxWrappers.length; i++) {
          (function(i) {
            parallaxWrappers[i].addEventListener("mousemove", function(e) {
              var x = e.clientX,
                y = e.clientY,
                layers = parallaxWrappers[i].querySelectorAll(
                  self.nameSpaces.layers
                );
              for (var j = 0; j < layers.length; j++) {
                (function(j) {
                  var deep = layers[j].getAttribute(self.nameSpaces.deep),
                    disallow = layers[j].getAttribute("data-parallax-disallow"),
                    itemX = disallow && disallow === "x" ? 0 : x / deep,
                    itemY = disallow && disallow === "y" ? 0 : y / deep;
                  if (disallow && disallow === "both") return;
                  layers[j].style.transform =
                    "translateX(" + itemX + "%) translateY(" + itemY + "%)";
                })(j);
              }
            });
          })(i);
        }
      };
      this.init();
      return this;
    }
  },

  methods: {
    ...mapActions(["changeState"]),

    preventScroll(event) {
      event.preventDefault();
    },
    toogleMusic() {
      this.changeState();
    }
  }
};
</script>
